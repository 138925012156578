<template>
  <validation-observer ref="refFormObserver">
    <b-form class="p-2">
      <b-row>
        <b-col cols="12">
          <b-form-group>
            <label>
              <span class="x-require-indicator font-medium-2 x-text-bold">*</span>
              <span class="font-medium-1 x-text-bold text-body">配置名称：</span>
            </label>
            <validation-provider
              #default="{ errors }"
              name="设备ID"
              rules="required"
            >
              <b-form-input
                v-model="formData.name"
                :state="errors.length > 0 ? false:null"
                placeholder="请输入配置名称"
                :readonly="isView"
              />
              <small class="text-danger" v-if="errors[0]">配置名称不能为空</small>
            </validation-provider>
          </b-form-group>
        </b-col>
        <b-col cols="12">
          <b-form-group>
            <label>
              <span class="x-require-indicator font-medium-2 x-text-bold">*</span>
              <span class="font-medium-1 x-text-bold text-body">通知类型：</span>
            </label>
            <validation-provider
              #default="{ errors }"
              name="通知类型"
              rules="required"
            >
              <v-select
                v-model="typeVal"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="types"
                placeholder="通知类型"
                label="name"
                :disabled="isView"
                :reduce="option => option.id">
                <template slot="no-options">{{$t('noData')}}</template>
              </v-select>
              <small class="text-danger" v-if="errors[0]">请选择通知类型</small>
            </validation-provider>
          </b-form-group>
        </b-col>
        <b-col cols="12">
          <b-form-group>
            <label>
              <span class="x-require-indicator font-medium-2 x-text-bold">*</span>
              <span class="font-medium-1 x-text-bold text-body">服务商：</span>
            </label>
            <validation-provider
              #default="{ errors }"
              name="服务商"
              rules="required"
            >
              <v-select
                v-model="providerVal"
                :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="providersList"
                placeholder="服务商"
                label="name"
                :disabled="isView"
                :reduce="option => option.id">
                <template slot="no-options">{{$t('noData')}}</template>
              </v-select>
              <small class="text-danger" v-if="errors[0]">请选择服务商</small>
            </validation-provider>
          </b-form-group>
        </b-col>
      </b-row>
      <b-row md="24"  v-if="propertiesList.length > 0">
          <b-col v-for="properties in propertiesList" :key="properties.name" cols="12" >
            <b-form-group>
              <label>
                <span class="font-medium-1 x-text-bold text-body">{{properties.name}}：</span>
              </label>
              <validation-provider
                :name="`configuration.${properties.property}`"
              >
                <b-form-input
                  v-if="properties.property !== 'networkId' && properties.property !== 'properties'"
                  v-model="formData.configuration[properties.property]"
                  :placeholder="`请输入${properties.name}`"
                  :readonly="isView"
                />
                <v-select
                  v-if="properties.property === 'networkId'"
                  v-model="formData.configuration[properties.property]"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  :options="networkList"
                  placeholder="网络组件"
                  label="name"
                  :disabled="isView"
                  :reduce="option => option.id">
                  <template slot="no-options">{{$t('noData')}}</template>
                </v-select>
                <div
                  v-if="properties.property === 'properties'"
                  :style="{height: trHeight}"
                  class="repeater-form"
                >
                  <b-form-group>
                    <b-row
                      v-for="(item, index) in formData.configuration[properties.property]"
                      :id="item.id"
                      :key="item.index"
                      class="pl-2 pt-1"
                      ref="row"
                    >
                      <!-- Item Name -->
                      <b-col md="3">
                        <b-form-group
                        >
                          <b-form-input
                            v-model="item.name"
                            placeholder="key"
                            :readonly="isView"
                          />
                        </b-form-group>
                      </b-col>
                      <div style="margin-top: 8px">
                        <feather-icon
                          size="15"
                          icon="ChevronRightIcon"
                        />
                      </div>
                      <b-col md="3">
                        <b-form-group
                        >
                          <b-form-input
                            v-model="item.value"
                            placeholder="value"
                            :readonly="isView"
                          />
                        </b-form-group>
                      </b-col>
                      <div style="margin-top: 8px">
                        <feather-icon
                          size="15"
                          icon="ChevronRightIcon"
                        />
                      </div>
                      <b-col md="3">
                        <b-form-group
                        >
                          <b-form-input
                            v-model="item.description"
                            placeholder="说明"
                            :readonly="isView"
                          />
                        </b-form-group>
                      </b-col>

                      <!-- Remove Button -->
                      <b-col
                        v-if="!isView"
                        md="2"
                      >
                        <b-button
                          v-ripple.400="$x.ripple.danger"
                          variant="danger"
                          class="mt-0"
                          @click="removeItem(index)"
                        >
                          <feather-icon
                            icon="Trash2Icon"
                            class="mr-10"
                          />
                        </b-button>
                      </b-col>
                    </b-row>
                  </b-form-group>
                  <b-button
                    v-if="!isView"
                    v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                    variant="primary"
                    @click="repeateAgain"
                    class="mb-1 ml-2"
                  >
                    <feather-icon
                      icon="PlusIcon"
                      class="mr-25"
                    />
                    <span>添加配置</span>
                  </b-button>
                </div>
              </validation-provider>
            </b-form-group>
          </b-col>
        </b-row>
        <b-col
          cols="12"
          class="mt-1"
        >
          <b-button
            v-if="!isView"
            variant="primary"
            type="submit"
            @click.prevent="validationForm"
          >
            确定
          </b-button>
        </b-col>
    </b-form>
  </validation-observer>
</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
  BFormInput, BFormGroup, BForm, BRow, BCol, BButton,
} from 'bootstrap-vue'
import { heightTransition } from '@core/mixins/ui/transition'
import vSelect from 'vue-select'
import {
  required,
} from '@validations'
import {
  metadata, getNetworkByTypeId, add,
} from '@/api/system/notice/config'

export default {
  components: {
    ValidationProvider,
    ValidationObserver,
    BFormInput,
    BFormGroup,
    BForm,
    BRow,
    BCol,
    BButton,
    vSelect,
  },
  mixins: [heightTransition],
  props: {
    isNew: {
      type: Boolean,
      default: true,
    },
    isView: {
      type: Boolean,
      default: false,
    },
    visible: {
      type: Boolean,
      default: false,
    },
    config: {
      type: Object,
      default: () => {},
    },
    types: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      // 网络组件列表
      networkList: [],
      providerVal: undefined,
      typeVal: undefined,
      providersList: [],
      // 服务商所需参数
      propertiesList: [],
      // 邮件其他参数数组
      otherConfgs: [
        {
          id: undefined,
          name: undefined,
          value: undefined,
          descrption: undefined,
        },
      ],
      formData: {
        id: undefined,
        name: undefined,
        type: undefined,
        provider: undefined,
        configuration: {
          properties: [],
        },
      },
      required,
    }
  },
  created() {
  },
  watch: {
    visible(val) {
      if (val === true) {
        if (this.isNew === false) {
          console.log('1111111', this.config)
          this.typeVal = this.config.type
          this.providerVal = this.config.provider
          this.formData = this.config
        } else {
          this.$refs.refFormObserver.reset()
          this.formData = {
            id: undefined,
            name: undefined,
            type: undefined,
            provider: undefined,
            configuration: {
              properties: [],
            },
          }
          this.propertiesList = []
          this.typeVal = undefined
          this.providerVal = undefined
        }
      }
    },
    typeVal(val) {
      this.types.forEach(type => {
        if (val === type.id) {
          let providers = []
          type.providerInfos.forEach(provider => {
            let proObj = {
              name: provider.name,
              id: provider.id,
            }
            providers.push(proObj)
          })
          this.providersList = providers
          if (this.isNew) {
            this.formData.configuration = {
              properties: [],
            }
          }
        }
      })
      if (val === 'email') {
        window.addEventListener('resize', this.initTrHeight)
      }
      this.formData.type = val
    },
    providerVal(val) {
      metadata(this.typeVal, val).then(resp => {
        this.propertiesList = resp.data.result.properties
      })
      if (this.typeVal === 'network') {
        getNetworkByTypeId(val).then(res => {
          this.networkList = res.data.result
        })
      }
      this.formData.provider = val
    },
  },
  methods: {
    validationForm() {
      this.$refs.refFormObserver.validate().then(success => {
        if (success) {
          add(this.formData).then(() => {
            this.$xtoast.success('编辑成功！')
            this.$emit('editSuccess')
          })
        }
      })
    },
    repeateAgain() {
      this.formData.configuration.properties.push({
        id: this.nextTodoId += this.nextTodoId,
      })

      this.$nextTick(() => {
        this.trAddHeight(this.$refs.row[0].offsetHeight)
      })
    },
    removeItem(index) {
      this.formData.configuration.properties.splice(index, 1)
      this.trTrimHeight(this.$refs.row[0].offsetHeight)
    },
    initTrHeight() {
      this.trSetHeight(null)
      this.$nextTick(() => {
        this.trSetHeight(200)
      })
    },
  },
}
</script>

<style lang="scss">
</style>
