<template>
  <div>
    <x-table
      title="通知配置"
      ref="configTable"
      customXForm
      :card="true"
      tableType="list"
      :options="options"
      :hideSearchBar="false"
      @rowAdd="rowAdd"
      @rowView="rowView"
      @export="exportAll"
      @search="searchHandle"
      @rowDownLoad="rowDownLoad"
      @rowEdit="rowEdit"
      @rowDebug="rowDebug"
      @rowRecord="rowRecord"
      @rowDelete="rowDelete">
      <template slot="actions">
      </template>
      <template slot="provider" slot-scope="scope">
        <b-badge variant="light-success">
          {{getProvider(scope.row.type,scope.row.provider)}}
        </b-badge>
      </template>
    </x-table>
    <b-sidebar
      idebar-class="sidebar-xl"
      bg-variant="white"
      right
      backdrop
      shadow
      width="50%"
      :visible="visibleFlag"
      @hidden="hideForm"
    >
      <config-form
        ref="form"
        :isNew="isNew"
        :isView="isView"
        :config="config"
        :types="types"
        :visible="visibleFlag"
        @editSuccess="editSuccess"
      />
    </b-sidebar>
    <b-sidebar
      idebar-class="sidebar-xl"
      bg-variant="white"
      right
      backdrop
      shadow
      width="50%"
      :visible="modalFlag"
      @hidden="resetModal"
    >
      <!-- @hidden="resetModal"
      @ok="handleOk" -->
    <notice-record
      v-if="modalFlag && recordId"
      :notifierId="recordId"
    />
    </b-sidebar>
    <b-modal
      ref="debugModal"
      title-class="x-text-bold"
      body-class="pl-4 pr-4"
      cancel-variant="flat-secondary"
      ok-title="保存"
      cancel-title="取消"
      centered
      title="调试"
      size="lg"
      :visible="debugModalFlag"
      @hidden="resetDebugModal"
      @ok="send"
    >
      <x-form-validator ref="refFormObserver">
        <x-form-feild label="通知模板">
          <v-select
            v-model="template"
            :options="templateList"
            label="name"
            placeholder="请选择模板"
          >
            <template slot="no-options">
              {{ $t('noData') }}
            </template>
          </v-select>
        </x-form-feild>
        <x-form-feild label="变量">
          <b-form-textarea
            v-model="context"
            placeholder='请输入变量 如：{ "aa" ： "aa"}'
          />
        </x-form-feild>
      </x-form-validator>
    </b-modal>
  </div>
</template>
<script>
import XTable from '@core/components/cx/table/XTable.vue'
import {
  BSidebar, BModal, BFormTextarea, BBadge,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import XFormValidator from '@/@core/components/cx/table/XFormValidator.vue'
import XFormFeild from '@/@core/components/cx/table/XFormFeild.vue'
import {
  query, types, metadata, remove, getTemplate, send,
} from '@/api/system/notice/config'
import configForm from './configForm.vue'
import noticeRecord from './noticeeRecord.vue'

export default {
  components: {
    XTable,
    configForm,
    BSidebar,
    BModal,
    noticeRecord,
    XFormValidator,
    XFormFeild,
    vSelect,
    BFormTextarea,
    BBadge,
  },
  data() {
    return {
      // 调试ID
      configId: undefined,
      // 调试参数
      context: '',
      // 调试模板
      template: {},
      // 调试模板选择数组
      templateList: [],
      debugModalFlag: false,
      modalFlag: false,
      modalWidth: '900',
      recordId: undefined,
      isView: false,
      isNew: false,
      // sidebar显隐控制参数
      visibleFlag: false,
      config: undefined,
      types: [],
      content: [{
        prop: 1,
        title: '第一步',
        content: '啊哈大口袋里',
      }, {
        prop: 2,
        title: '第二部',
        content: '打卡来看待',
      }],
      options: {
        formType: 'sidebar',
        formWidth: '60%',
        // formWidth: 'lg',
        rowSelectable: false,
        searchFold: true,
        lableVertical: true,
        // 新增按钮
        addBtn: true,
        // 导出按钮
        exportBtn: true,
        // 打印按钮
        printBtn: false,
        // showActions: false,
        actions: [{ action: 'view', name: '查看', icon: 'EyeIcon', customXForm: true },
          // actions: [{ action: 'view', name: '查看', icon: 'EyeIcon', url: '/device-center/device/view/{id}' },
          { action: 'edit', name: '编辑', icon: 'EditIcon', customXForm: true },
          { action: 'debug', name: '调试', icon: 'SkipForwardIcon' },
          { action: 'downLoad', name: '下载', icon: 'DownloadIcon' },
          { action: 'record', name: '通知记录', icon: 'FileTextIcon' },
          { action: 'delete', name: '删除', icon: 'Trash2Icon' }],
        actionFilter: action => action === 'view' || action === 'edit' || action === 'delete' || action === 'downLoad' || action === 'record' || action === 'debug',
        columns: [{
          label: 'id',
          rowShow: false,
          labelShow: true,
          prop: 'id',
          searchShow: false,
          editShow: false,
          addShow: false,
          rules: {
            rule: 'required',
            message: '请输入id',
          },
        }, {
          label: '配置名称',
          labelShow: true,
          prop: 'name',
          searchShow: true,
          rules: {
            rule: 'required',
            message: '请输入配置名称',
          },
        }, {
          label: '通知类型',
          labelShow: true,
          prop: 'type',
          onDictSelected: (option, columns, formData) => {
            this.types.forEach(type => {
              if (option === type.id) {
                let providers = []
                type.providerInfos.forEach(provider => {
                  let proObj = {
                    label: provider.name,
                    value: provider.id,
                  }
                  providers.push(proObj)
                })
                columns[3].dictData = providers
                formData.provider = undefined
              }
            })
          },
          type: 'select',
          dictUrl: '/notifier/config/types',
          rowSelect: true,
          selectVariant: () => 'light-primary',
          // getDictLabel: option => option.label + `（${option.value})`,
          props: {
            label: 'name',
            value: 'id',
          },
          rules: {
            rule: 'required',
            message: '请选择通知类型',
          },
        }, {
          label: '服务商',
          labelShow: true,
          prop: 'provider',
          onDictSelected: (option, columns, formData) => {
            if (formData) {
              metadata(formData.type, option).then(resp => {
                /* columns[4].addShow = true
                columns[4].editShow = true */
                let propertiesArr = []
                resp.data.result.properties.forEach(properties => {
                  let obj = {
                    label: properties.name,
                    labelShow: true,
                    prop: properties.property,
                    addShow: true,
                    editShow: true,
                  }
                  if (properties.type.expands && properties.type.expands.required) {
                    obj.rules = {
                      rule: 'required',
                      message: '请选择通知类型',
                    }
                  }
                  propertiesArr.push(obj)
                })
                this.$nextTick(() => {
                  columns[4].children = propertiesArr
                })
              })
            }
          },
          type: 'select',
          dictData: [],
          searchShow: false,
          // getDictLabel: option => option.label + `（${option.value})`,
          rules: {
            rule: 'required',
            message: '请选择服务商',
          },
        },
        {
          label: '配置',
          labelShow: false,
          prop: 'configuration',
          rowShow: false,
          viewShow: false,
          addShow: false,
          editShow: false,
          searchShow: false,
          children: [],
        },
        ],
      },
    }
  },
  created() {
    types().then(resp => {
      this.types = resp.data.result
    })
  },
  methods: {
    searchHandle(page, params, done) {
      query(page.pageIndex - 1, page.pageSize, params).then(resp => {
        done(resp.data.result.data, resp.data.result.total)
      })
    },
    rowDelete(data, done) {
      remove(data.id).then(() => {
        done()
      })
    },
    getProvider(type, provider) {
      for (let t of this.types) {
        if (t.id === type) {
          for (let p of t.providerInfos) {
            if (p.id === provider) {
              return p.name
            }
          }
        }
      }
      return ''
    },
    hideForm() {
      this.visibleFlag = false
      this.config = undefined
      this.isNew = false
      this.isView = false
    },
    editSuccess() {
      this.visibleFlag = false
      this.config = undefined
      this.isNew = false
      this.isView = false
      this.$refs.configTable.__searchHandle()
    },
    // 下载配置
    rowDownLoad(data, done) {
      // 创建隐藏的可下载链接
      const eleLink = document.createElement('a')
      eleLink.download = `通知配置-${data.name || new Date().format('YYYY/MM/DD HH:mm:ss')}.json`
      eleLink.style.display = 'none'
      // 字符内容转变成blob地址
      const blob = new Blob([JSON.stringify(data)])
      eleLink.href = URL.createObjectURL(blob)
      // 触发点击
      document.body.appendChild(eleLink)
      eleLink.click()
      // 然后移除
      document.body.removeChild(eleLink)
      done()
    },
    exportAll() {
      // 创建隐藏的可下载链接
      const eleLink = document.createElement('a')
      const date = new Date().cxFormat('YYYY/MM/DD HH:mm:ss')
      eleLink.download = `模板配置-${date || '0000'}.json`
      eleLink.style.display = 'none'
      // 字符内容转变成blob地址
      const blob = new Blob([JSON.stringify(this.$refs.configTable.pageDatas)])
      eleLink.href = URL.createObjectURL(blob)
      // 触发点击
      document.body.appendChild(eleLink)
      eleLink.click()
      // 然后移除
      document.body.removeChild(eleLink)
    },
    // 查看通知记录
    rowRecord(data) {
      this.recordId = data.id
      this.modalFlag = true
    },
    resetModal() {
      this.recordId = undefined
      this.modalFlag = false
    },
    resetDebugModal() {
      this.debugModalFlag = false
      this.configId = undefined
      this.template = undefined
      this.context = ''
      this.templateList = []
    },
    rowAdd() {
      this.visibleFlag = true
      this.isNew = true
    },
    rowEdit(data) {
      this.isNew = false
      this.isView = false
      this.visibleFlag = true
      this.config = this._.cloneDeep(data)
    },
    rowView(data) {
      this.isNew = false
      this.isView = true
      this.visibleFlag = true
      this.config = this._.cloneDeep(data)
    },
    rowDebug(data) {
      this.configId = data.id
      getTemplate(data.type, data.provider).then(resp => {
        this.templateList = resp.data.result.data
        this.debugModalFlag = true
      })
    },
    send(bvModalEvt) {
      bvModalEvt.preventDefault()

      if (!this.template.id) {
        this.$xtoast.warning('请选择模板信息！')
        return
      }
      let obj = {
        context: this.context ? this.context : {},
        template: this.template,
      }
      send(this.configId, obj).then(resp => {
        if (resp.status === 200) {
          this.$xtoast.success('发送成功')
          this.debugModalFlag = false
        }
      })
    },
  },
}
</script>

<style lang="scss" scoped>
  .per-page-selector {
    width: 90px;
  }
</style>

<style lang="scss">
</style>
