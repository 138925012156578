import request from '@/auth/jwt/useJwt'

/*  查询列表  */
// eslint-disable-next-line arrow-body-style
export function query(pageIndex, pageSize, params) {
  let requestUrl = `notifier/config/_query?pageIndex=${pageIndex}&pageSize=${pageSize}`
  let index = 0
  if (params !== null) {
    if (params.name) {
      requestUrl += `&terms[${index}].column=name&terms[${index}].value=${params.name}`
      index++
    }
    if (params.type) {
      requestUrl += `&terms[${index}].column=type&terms[${index}].value=${params.type}`
    }
  }
  return request.axiosIns({
    url: requestUrl,
    method: 'get',
  })
}

// eslint-disable-next-line arrow-body-style
export function queryRecord(pageIndex, pageSize, params) {
  let requestUrl = `notify/history/_query?pageIndex=${pageIndex}&pageSize=${pageSize}&terms[0].column=notifierId&terms[0].value=${params.notifierId}&sorts%5B0%5D.name=notifyTime&sorts%5B0%5D.order=desc`
  let index = 1
  if (params.state) {
    requestUrl += `&terms[${index}].column=state&terms[${index}].value=${params.state}`
    index += 1
  }
  if (params.notifyTime) {
    requestUrl += `&terms[${index}].column=notifyTime$btw&terms[${index}].value=${params.notifyTime}`
  }
  return request.axiosIns({
    url: requestUrl,
    method: 'get',
  })
}
// 加载通知类型
export function types() {
  return request.axiosIns({
    url: 'notifier/config/types',
    method: 'get',
  })
}
// 根据类型和服务商插叙所需要的参数
export function metadata(typeId, providerId) {
  return request.axiosIns({
    url: `notifier/config/${typeId}/${providerId}/metadata`,
    method: 'get',
  })
}
// 当通知类型是网络组件的时候根据服务商查询该服务商类型下的网络组件
export function getNetworkByTypeId(typeId) {
  return request.axiosIns({
    url: `network/config/_query/no-paging?paging=false&sorts%5B0%5D.name=id&sorts%5B0%5D.order=desc&terms%5B0%5D.column=type&terms%5B0%5D.value=${typeId}`,
    method: 'get',
  })
}
// 新增通知配置
export function add(data) {
  return request.axiosIns({
    url: 'notifier/config',
    method: 'PATCH',
    data,
  })
}
// 删除通知配置
export function remove(id) {
  return request.axiosIns({
    url: `notifier/config/${id}`,
    method: 'DELETE',
  })
}

// 查询调试模板
export function getTemplate(type, provider) {
  return request.axiosIns({
    url: `notifier/template/_query?paging=false&terms%5B0%5D.column=type&terms%5B0%5D.value=${type}&terms%5B1%5D.column=provider&terms%5B1%5D.value=${provider}`,
    method: 'get',
  })
}
// 发送测试
export function send(configId, data) {
  return request.axiosIns({
    url: `notifier/${configId}/_send`,
    method: 'post',
    data,
  })
}
