<template>
  <div>
    <x-table
      title="通知记录"
      ref="configTable"
      :customXForm="true"
      :card="false"
      tableType="list"
      :options="options"
      :hideSearchBar="false"
      @rowView="rowView"
      @search="searchHandle">
    </x-table>
    <b-modal
      id="modal-form"
      ref="recordModal"
      title-class="x-text-bold"
      body-class="pl-4 pr-4"
      cancel-variant="flat-secondary"
      ok-title="确定"
      cancel-title="取消"
      centered
      title="通知明细"
      :visible="modalFlag"
      @hidden="resetModal"
    >
      <json-viewer :value="context" :expand-depth=20 copyable sort boxed></json-viewer>
    </b-modal>
  </div>
</template>

<script>
import XTable from '@core/components/cx/table/XTable.vue'
import {
  BModal,
} from 'bootstrap-vue'
import {
  queryRecord,
} from '@/api/system/notice/config'

export default {
  components: {
    XTable,
    BModal,
  },
  props: {
    notifierId: {
      type: String,
      default: undefined,
    },
  },
  data() {
    return {
      modalFlag: false,
      context: undefined,
      options: {
        formType: 'sidebar',
        formWidth: '60%',
        // formWidth: 'lg',
        rowSelectable: false,
        searchFold: true,
        lableVertical: true,
        // 新增按钮
        addBtn: false,
        // 导出按钮
        exportBtn: false,
        // 打印按钮
        printBtn: false,
        // showActions: false,
        actions: [{ action: 'view', name: '详情', icon: 'EyeIcon', customXForm: true }],
        actionFilter: action => action === 'view',
        columns: [{
          label: 'ID',
          labelShow: true,
          prop: 'id',
          searchShow: false,
        }, {
          label: '时间',
          labelShow: true,
          prop: 'notifyTime',
          type: 'datetimerange',
          searchShow: true,
        }, {
          label: '状态',
          labelShow: true,
          prop: 'state',
          type: 'select',
          rowSelectDot: true,
          dictData: [{ value: 'success', text: '成功' }, { value: 'error', text: '失败' }],
          selectVariant: prop => {
            if (prop.value === 'success') {
              return 'text-success'
            }
            return 'text-danger'
          },
          props: { label: 'text', value: 'value' },
          editShow: false,
          addShow: false,
          viewShow: false,
          searchShow: true,
        },
        ],
      },
    }
  },
  created() {
  },
  watch: {
  },
  methods: {
    searchHandle(page, params, done) {
      if (params === null) {
        params = {
          notifierId: this.notifierId,
        }
      } else {
        params.notifierId = this.notifierId
      }
      queryRecord(page.pageIndex - 1, page.pageSize, params).then(resp => {
        done(resp.data.result.data, resp.data.result.total)
      })
    },
    resetModal() {
      this.context = undefined
      this.modalFlag = false
    },
    rowView(data) {
      this.context = data.context || '{}'
      this.modalFlag = true
    },
  },
}
</script>

<style lang="scss">
</style>
